<template>
  <div>
    <div class="loading flex flex-row items-center justify-center fixed left-0 top-20 md:top-40 lg:top-60 w-full bottom-0 z-100 bg-black transition-all duration-500" :class="[{'invisible opacity-0': state && region}]">
      <loading-spinner />
    </div>
    <div class="page min-h-screen">
      <div class="region" v-if="state && region">
      <div class="outer-container">
        <div class="inner-container">
          <div class="breadcrumbs mt-12 text-grey-400 text-12 uppercase">
            <router-link to="/discover" class="hover:text-white transition-colors">Discover</router-link><span class="divider inline-block px-4">&gt;</span><router-link :to="`/discover/${this.$route.params.state}`" class="hover:text-white transition-colors">{{ state.elements.page__page_title.value }}</router-link><span class="divider inline-block px-4">&gt;</span><span class="text-white">{{ region.elements.page__h1_title.value.length ? region.elements.page__h1_title.value : region.elements.page__page_title.value }}</span>
          </div>
          <div class="mt-16">
            <bleed-container bleed="right">
              <div class="hero">
                <div class="hero-image relative">
                  <img v-if="region.elements.hero__background_image_mobile.value.length" class="lazyload block w-full" :data-lowsrc="`${region.elements.hero__background_image_mobile.value[0].url}?w=100`" :data-srcset="`${region.elements.hero__background_image.value[0].url}?w=767&h=400&fit=crop 767w, ${region.elements.hero__background_image.value[0].url}?w=1000&h=450&fit=crop 1024w, ${region.elements.hero__background_image.value[0].url}?h=450 2000w`" alt="">
                  <img v-else class="lazyload block w-full" :data-lowsrc="`${region.elements.hero__background_image.value[0].url}?w=100`" :data-srcset="`${region.elements.hero__background_image.value[0].url}?w=767&h=400&fit=crop 767w, ${region.elements.hero__background_image.value[0].url}?w=1000&h=450&fit=crop 1024w, , ${region.elements.hero__background_image.value[0].url}?h=450 2000w`" alt="">
                  <div class="accreditation" v-if="region.elements.hero__background_image_credit.value">
                    <div class="icon"></div>
                    <div class="overlay">
                      <div class="credit-title">{{region.elements.hero__background_image_credit.value}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </bleed-container>

            <div class="copy">
              <div class="heading">
                <h1>{{ region.elements.page__h1_title.value.length ? region.elements.page__h1_title.value : region.elements.page__page_title.value }} —</h1>

                <div class="associated-region" v-if="associatedRegion">
                  Connect with the regional association
                  <associated-region-tile :region="associatedRegion" :show-pin="false" />
                </div>

              </div>

              <div class="content">
                <div class="lead-copy space-y-8" v-html="region.elements.connect_lead_copy.value.length > 20 ? region.elements.connect_lead_copy.value : region.elements.lead_copy.value"></div>
                <div class="intro-copy space-y-8" v-html="region.elements.connect_intro_copy.value.length > 20 ? region.elements.connect_intro_copy.value : region.elements.intro_copy.value"></div>
              </div>
            </div>

            <div class="specifications">
              <div class="lat col">
                <div class="inner">
                  <div class="label">Latitude</div>
                  <div class="value">{{ region.elements.latitude.value }}</div>
                </div>
              </div>

              <div class="lng col">
                <div class="inner">
                  <div class="label">Longitude</div>
                  <div class="value">{{ region.elements.longitude.value }}</div>
                </div>
              </div>
              <div class="area col">
                <div class="inner">
                  <div class="label">Total vineyard area</div>
                  <div class="value">{{ region.elements.total_vineyard_area.value }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="map relative">
        <img class="lazyload block w-full" :data-lowsrc="`${region.elements.location_map.value[0].url}?w=100`" :data-srcset="`${region.elements.location_map.value[0].url}?w=500 500w, ${region.elements.location_map.value[0].url}?w=768 768w, ${region.elements.location_map.value[0].url}?w=1024 1024w, , ${region.elements.location_map.value[0].url} 2000w`" alt="">
      </div>

      <div class="outer-container">
        <div class="inner-container">
          <div class="climate-alt">
            <h2>Climate and altitude</h2>

            <div class="description" v-html="region.elements.climate_and_altitude_description.value"></div>
          </div>
          <div class="specifications">
            <div class="rainfall col">
              <div class="inner">
                <div class="label">Rainfall</div>
                <div class="value">{{ region.elements.growing_season_rainfall.value }}</div>
              </div>
            </div>

            <div class="temperature col">
              <div class="inner">
                <div class="label">Temperature</div>
                <div class="value">{{ region.elements.mean_temperature.value }}</div>
              </div>
            </div>

            <div class="altitude col">
              <div class="inner">
                <div class="label">Altitude</div>
                <div class="value">{{ region.elements.altitude.value }}</div>
              </div>
            </div>
          </div>


          <div class="single-image-copy">
            <div class="image">
              <bleed-container bleed="left">
                <img class="lazyload block w-full" :data-lowsrc="`${region.elements.weather_image.value[0].url}?w=100`" :data-srcset="`${region.elements.weather_image.value[0].url}?w=500 500w, ${region.elements.weather_image.value[0].url}?w=768 768w, ${region.elements.weather_image.value[0].url}?w=1024 1024w, , ${region.elements.weather_image.value[0].url} 2000w`" alt="">
                <div class="accreditation" v-if="region.elements.weather_image_credit.value">
                  <div class="icon"></div>
                  <div class="overlay">
                    <div class="credit-title">{{region.elements.weather_image_credit.value}}</div>
                  </div>
                </div>
              </bleed-container>
            </div>
            <div class="content">
              <h3>The environment</h3>
              <div class="space-y-8" v-html="region.elements.the_environment_description.value"></div>
            </div>
          </div>

          <div class="no-image-copy">
            <div class="image relative"></div>
            <div class="content">
              <h3>Climate</h3>
              <div class="space-y-8" v-html="region.elements.weather_description.value"></div>
            </div>
          </div>

          <div class="single-image-copy top">
            <div class="image">
              <bleed-container bleed="left">
                <img class="lazyload block w-full" :data-lowsrc="`${region.elements.soil_image.value[0].url}?w=100`" :data-srcset="`${region.elements.soil_image.value[0].url}?w=500 500w, ${region.elements.soil_image.value[0].url}?w=768 768w, ${region.elements.soil_image.value[0].url}?w=1024 1024w, , ${region.elements.soil_image.value[0].url} 2000w`" alt="">
                <div class="accreditation" v-if="region.elements.soil_image_credit.value">
                  <div class="icon"></div>
                  <div class="overlay">
                    <div class="credit-title">{{region.elements.soil_image_credit.value}}</div>
                  </div>
                </div>
              </bleed-container>
            </div>
            <div class="content">
              <h3>Soil</h3>
              <div class="space-y-8" v-html="region.elements.soil_description.value"></div>
            </div>
          </div>

          <div class="view-all mt-24">
            <div class="image">
              <bleed-container bleed="right">
                <img class="lazyload block w-full" :data-lowsrc="`${regionContent[region.elements.image_gallery.value].elements.image.value[0].url}?w=100`" :data-srcset="`${regionContent[region.elements.image_gallery.value].elements.image.value[0].url}?w=500 500w, ${regionContent[region.elements.image_gallery.value].elements.image.value[0].url}?w=768 768w, ${regionContent[region.elements.image_gallery.value].elements.image.value[0].url}?w=1024 1024w, , ${regionContent[region.elements.image_gallery.value].elements.image.value[0].url} 2000w`" alt="">
                <div class="accreditation" v-if="regionContent[region.elements.image_gallery.value].elements.image_credit.value">
                  <div class="icon"></div>
                  <div class="overlay">
                    <div class="credit-title">{{regionContent[region.elements.image_gallery.value].elements.image_credit.value}}</div>
                  </div>
                </div>
              </bleed-container>
            </div>
            <div class="content">
              <div class="text-22 max-w-screen-xs">View all of the wines and products related to this region at the Expo</div>
              <div>
                <router-link :to="`/expo?${$store.state.winerySort}%5BrefinementList%5D%5Bregion%5D%5B0%5D=${regionName}&${$store.state.productSort}%5BrefinementList%5D%5Bregion%5D%5B0%5D=${regionName}&scroll=true`" class="btn-transparent mt-8" @click="$store.commit('setSearchView', 'wineries')">View all</router-link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
import Consumer from "@/kontent/consumer";
import Kontent from "@/kontent";
import BleedContainer from '../components/BleedContainer';
import LoadingSpinner from "@/components/LoadingSpinner";
import AssociatedRegionTile from "@/components/AssociatedRegionTile";
import Bus from '@/events/EventBus';
export default {
  name: 'DiscoverRegion',
  metaInfo () {
    if (!this.region) {
      return null
    }

    let meta = [
      {property: 'og:site_name', content: 'CONNECT'},
      {property: 'og:url', content: window.location.href},
      {property: 'og:title', content: this.region.elements.page__meta_title.value.length ? this.region.elements.page__meta_title.value : this.region.elements.page__page_title.value},
      {property: 'og:description', content: this.region.elements.page__page_description.value},
      {name: 'title', content: this.region.elements.page__meta_title.value.length ? this.region.elements.page__meta_title.value : this.region.elements.page__page_title.value},
      {name: 'keywords', content: this.region.elements.page__meta_keywords.value},
      {name: 'description', content: this.region.elements.page__meta_description.value.length ? this.region.elements.page__meta_description.value : this.region.elements.page__page_description.value},
      {name: 'twitter:card', content: 'summary_large_image'},
      {name: 'twitter:title', content: this.region.elements.page__meta_title.value.length ? this.region.elements.page__meta_title.value : this.region.elements.page__page_title.value},
      {name: 'twitter:description', content: this.region.elements.page__meta_description.value.length ? this.region.elements.page__meta_description.value : this.region.elements.page__page_description.value}
    ]

    // if there's an image
    if (this.region.elements.page__main_image.value.length) {
      meta.push({property: 'og:image', content: this.region.elements.page__main_image.value[0].url})
      meta.push({name: 'twitter:image', content: this.region.elements.page__main_image.value[0].url})
    }

    // if there's a type
    if (this.region.elements.page__page_type.value.length) {
      meta.push({property: 'og:type', content: this.region.elements.page__page_type.value[0].name})
    }

    return {
      title: this.region.elements.page__page_title.value,
      meta: meta
    }
  },
  components: {AssociatedRegionTile, LoadingSpinner, BleedContainer},
  props: {},
  data () {
    return {
      region: null,
      state: null,
      regionContent: null,
      associatedRegion: null
    }
  },
  methods: {
    showPopup() {
      Bus.$emit('show-register-popup')
    }
  },
  mounted () {
    Consumer.getItem('region', this.$route.params.region, 1).then((res) => {
      this.region = res.data.items[0]
      this.regionContent = res.data.modular_content

      setTimeout(function(){window.prerenderReady = true}, 1000)
      Analytics.trackPage('Discover ' + this.region.elements.page__page_title.value)
      if (this.$auth.isAuthenticated) {
        this.$store.dispatch("getFavouriteProducts")
        this.$store.dispatch("getFavouriteWineries")
      }
      const regionName = this.region.elements.page__h1_title.value.length ? this.region.elements.page__h1_title.value : this.region.elements.page__page_title.value

      Kontent.getAssociatedRegionForRegion(regionName).then((res) => {
        this.associatedRegion = res.data.items[0]
      })
    })
    Consumer.getItem('state', this.$route.params.state, 0).then((res) => {
      this.state = res.data.items[0]
    })
  },
  computed: {
    regionName () {
      if (this.region) {
        switch (this.region.elements.page__h1_title.value.toLowerCase()) {
          case 'hunter valley':
            return 'hunter'
          default :
            return this.region.elements.page__h1_title.value.toLowerCase()
        }
      }
      return null
    }
  }
}
</script>

<style scoped lang="scss">

.alpaca-link {
  margin-top: 20px;
}
  .no-image-copy
  {
    padding:100px 0;
    max-width:500px;
    width:100%;
    margin:0 auto;
    h3
    {
      font-weight: 700;
      margin-bottom:40px;
    }
  }

  .single-image-copy
  {
    .content {
      margin-top: 20px;
    }
    h3 {

      font-weight: 700;
      margin-bottom:40px;
    }
    @media(min-width: 768px) {
      display:flex;
      align-items:flex-end;
      flex-direction:row;

      &.top {
        align-items: flex-start;
        .content {
          margin-top: 0;
        }
      }
      .image
      {
        width:55%;
        height:300px;
        position: relative;

        .bleed-container
        {
          height:100%;
        }

        img
        {
          object-fit:cover;
          position: absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
        }
      }

      .content
      {
        width:50%;
        padding-left:60px;
      }
    }
  }

  .climate-alt
  {
    background-color: #000;
    z-index:1;
    position: relative;
    padding-top:40px;

    @media(min-width: 768px) {
      margin-top:-60px;
      max-width:50%;
      padding:10px 50px;
    }

      h2 {
        margin-bottom: 20px;
        font-weight: bold;
        margin-top: 20px;

        @media(min-width: 768px) {
          margin-bottom: 40px;
        }
      }
  }

  .specifications
  {
    margin:0 auto;
    margin-top:100px;
    max-width:1000px;
    justify-content: space-between;
    padding-bottom:80px;

    @media(min-width: 768px) {
      display:flex;
      align-items:center;
      flex-direction:row;
    }

    .col
    {
      white-space: nowrap;
      margin-bottom:20px;

      @media(min-width: 768px) {
        width:33.333%;
        display:flex;
        align-items:center;
        text-align: center;
        flex-direction:column;
        margin-bottom:0;
      }

        .inner
      {
        display:inline-block;
        text-align:left;
      }
    }

    .label
    {
      font-weight: 600;
      color:#9A9A9A;
      font-size: 14px;
    }

    .value
    {
      font-size: 47px;
    }
  }

  .intro-copy
  {
    color:#959595;
  }

  .lead-copy {
    font-size: 18px;
    font-weight:600;
    line-height: 1.2;
    margin-bottom:40px;

    @media(min-width: 768px) {
      font-size: 26px;
    }

    @media(min-width: 1024px) {
      font-size: 34px;
    }
  }

  .copy
  {
    position: relative;
    z-index:1;
    background-color: #000;

    @media(min-width: 768px) {
      display:flex;
      margin-top:60px;
      flex-direction:row;
    }

    .associated-region {
      margin-top: 40px;
      max-width: 320px;
      .associated-region-tile {
        margin-top: 10px;
      }
    }

    .heading
    {
      padding-top:20px;
      padding-right:40px;
      background-color: #000;
      z-index:1;
      margin-top:-90px;
      margin-bottom:40px;

      @media(min-width: 768px){
        width:40%;
        margin-top:-200px;
      }

      h1 {
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .content {
      width:100%;
      @media(min-width: 768px) {
        width: 60%;
      }
    }
  }

  .hero-image
  {
    height:400px;

    img
    {
      position: absolute;
      top: 0;
      object-fit: cover;
      left:0;
      width:100%;
      height:100%;
    }

    @media(min-width: 768px)
    {
      height:450px;
      margin-left:20%;
    }
  }

  .view-all {
    .content {
      margin-top: 20px;
    }

    @media(min-width: 768px)
    {
      box: horizontal reverse;
      align-items: flex-end;
      margin-top: -40px;

      .content {
        padding-right: 60px;
      }

      .image
      {
        width:55%;
        height:300px;
        position: relative;

        .bleed-container
        {
          height:100%;
          margin-top: -80px;
        }

        img
        {
          object-fit:cover;
          position: absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
        }
      }
    }
  }
</style>
